export class Role {
  id: number;
  name: string;

  parentId: number;
  parent: Role;

  children: Role[];

  flags: string[] = [];

  constructor(id: number = null, parentId: number = null) {
    this.id = id;
    this.parentId = parentId;
  }

  public toString(): string {
    return `${this.name} (#${this.id})`;
  }
}

export class Roles {
  roles: Role[];

  constructor(roles?: Role[]) {
    if (roles) {
      this.roles = roles;
    } else {
      this.roles = [];
    }
  }

  getRole(id: number): Role {
    for (const role of this.roles) {
      if (id === role.id) {
        return role;
      }
    }

    return null;
  }
}
