import { Injectable } from '@angular/core';
import { Config } from '../../../../_config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Page, queryPaginated } from '../../../../_helpers/pagination';
import { Role } from '../_models';


@Injectable()
export class RoleService {

  baseUrl = Config.API_ENDPOINT + '/admin/role';

  constructor(
    private http: HttpClient
  ) {
  }

  list(urlOrFilter?: string | object): Observable<Page<Role>> {
    return queryPaginated<Role>(this.http, this.baseUrl + '/list', urlOrFilter);
  }

  get(id: string): Observable<Role> {
    return this.http.get<Role>(this.baseUrl + '/' + id);
  }

  save(role: Role): Observable<Role> {
    return this.http.post<Role>(this.baseUrl, role);
  }

  delete(role: Role): Observable<Role> {
    return this.http.delete<any>(this.baseUrl, {
      params: {
        roleId: role.id.toString(10)
      }
    });
  }
}
