export class User {
  id: number;
  last_name: string;
  first_name: string;
  login: string;
  password: string;
  e_mail: string;
  super_user: boolean;
  active: boolean;

  constructor(id: number = null) {
    this.id = id;
  }

  public toString(): string {
    return `${this.login} (#${this.id})`;
  }
}
