import { Injectable } from '@angular/core';
import { User } from '../_models';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../../../_config';
import { Page, queryPaginated } from '../../../../_helpers/pagination';

@Injectable()
export class UserService {

  private baseUrl = Config.API_ENDPOINT + '/admin/user';

  constructor(private http: HttpClient) {
  }

  public list(urlOrFilter?: string | object): Observable<Page<User>> {
    return queryPaginated<User>(this.http, this.baseUrl + '/list', urlOrFilter);
  }

  public get(id: string): Observable<User> {
    return this.http.get<User>(this.baseUrl + '/' + id);
  }

  public save(user: User): Observable<User> {
    return this.http.post<User>(this.baseUrl, user);
  }

  public delete(user: User): Observable<User> {
    return this.http.delete<any>(this.baseUrl, {
      params: {
        userId: user.id.toString(10)
      }
    });
  }
}
